const replace = {
  changeSMID: 'Change Heim ID',
  reqSMID: 'Heim ID *',
  SMID: 'Heim ID',
  SMIDTolltip: 'The Heim ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'Heim ID',
  smidLabel: 'Heim ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the Heim ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the Heim ID. The gateway is not deleted.'
};

export default replace;
