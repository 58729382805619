export const DEFAULT_FONT = 'Roboto';

export const INSTALLATION_ONGOING_COLOR = '#3998F9';
export const NOT_CONNECTED_STATUS_COLOR = '#F11D1D';
export const CONNECTED_COLOR = '#00A23A';

export const OK_COLOR = '#00A23A';
export const WARNING_COLOR = '#95C11F';
export const ERROR_COLOR = '#D1D700';
export const NOT_CONNECTED_MONITORING_COLOR = '#F11D1D';

export const PIE_CHART_COLORS = {
  countColor: '#040D17',
  itemHiddenStyle: '#040D17',
  itemHoverStyle: '#040D17',
  itemStyleColor: 'rgba(4, 13, 23, 0.5)',
  titleColor: '#040D17',
  bgColor: '#FFFFFF'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#040D17',
  itemHoverStyle: '#040D17',
  itemStyleColor: 'rgba(4, 13, 23, 0.5)',
  titleColor: '#040D17',
  bgColor: '#FFFFFF',
  OVERALL_ACTIVE_COLOR: '#00A23A',
  ONE_YEAR_ACTIVE_COLOR: '#95C11F',
  ONE_MONTH_ACTIVE_COLOR: '#D1D700',
  OVERALL_EMPTY_COLOR: '#E7F6ED',
  ONE_YEAR_EMPTY_COLOR: '#F5F9EA',
  ONE_MONTH_EMPTY_COLOR: '#FBFBE7'
};

export const tooltip = {
  backgroundColor: '#fff',
  style: {
    color: '#040D17',
    fontSize: '16px'
  }
};
