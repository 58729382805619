import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { email, required, parseToLowerCase } from '../../validation';
import { CustomTextInput, Checkbox } from '../../../components/ReduxFormFields';
import { LanguageSelector } from '../../../components/UIKit';
import { Footer, Logo } from '../../../components';

import i18n from '../../../i18n';

import '../../../App.css';
import { APP_ID, THERMONDO_APP_ID, HELION_APP_ID } from '../../../config';
import { closeModalWindow, CREATING_NEW_USER_NOTE_MODAL_ID } from '../../ModalWindow/slice';
import { CreatingNewUserNoteWindow } from '../../../modals';
import { useLazyThermondoSamlAuthRequestDataQuery } from '../services';

/**
 * SignInForm
 * @memberof module:SignIn
 * @description renders Sign In form
 * @extends Component
 */
const SignInForm = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  const [thermondoSamlAuthRequest] = useLazyThermondoSamlAuthRequestDataQuery();

  const dispatch = useDispatch();

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <CreatingNewUserNoteWindow toggleModal={() => dispatch(closeModalWindow({ modalID: CREATING_NEW_USER_NOTE_MODAL_ID }))} />
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row align-items-center justify-content-center">
            <div className="col-auto mr-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper row align-items-center">
          <div className="m-login__container">
            <div className="row align-items-center">
              <div className="m-login__signin col-xl-10 col-lg-10 col-md-12 col-sm-12">
                <div className="m-login__head">
                  <h3 className="m-login__title">{i18n.t('headerLogin')}</h3>
                </div>
                <div className="container-fluid">
                  <form
                    className="m-login__form m-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="email"
                        component={CustomTextInput}
                        label={i18n.t('mail')}
                        className="m-input"
                        validate={[required, email]}
                        parse={parseToLowerCase}
                        autoComplete="off"
                        preventAuto
                        languageToSync={i18n.language}
                      />
                    </div>
                    <div className="form-group m-form__group input-field">
                      <Field
                        name="password"
                        component={CustomTextInput}
                        label={i18n.t('pass')}
                        className="m-input"
                        type="password"
                        validate={[required]}
                        autoComplete="off"
                        preventAuto
                        languageToSync={i18n.language}
                      />
                    </div>
                    <div className="row m-login__form-sub mae">
                      <div className="col m-login__form-left">
                        <Field
                          type="checkbox"
                          name="remember"
                          component={Checkbox}
                          labelClass="m-checkbox terms m-checkbox--light remem-checkbox"
                          label={i18n.t('rememberMe')}
                        />
                      </div>
                      <div className="col m--align-right m-login__form-right">
                        <Link
                          to="forgot-password"
                          id="m_login_forget_password"
                          className="m-link"
                        >
                          {i18n.t('forgotPass')}
                        </Link>
                      </div>
                    </div>
                    <div className="m-login__form-action">
                      <button
                        disabled={pristine || submitting}
                        type="submit"
                        id="m_login_signin_submit"
                        className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air  m-login__btn m-login__btn--primary"
                      >
                        {i18n.t('loginbtn')}
                      </button>
                    </div>
                  </form>
                </div>
                {![HELION_APP_ID, THERMONDO_APP_ID].includes(APP_ID) && (
                  <div className="m-have-account">
                    <Link
                      to="/signup"
                      id="m_login_signup_cancel"
                      className="m-link m-link--light m-login__account-link"
                    >
                      {APP_ID === 'solarManagerEndUserApp' ? i18n.t('creatingAccbtn') : i18n.t('createNewInstaller')}
                    </Link>
                  </div>
                )}
                {THERMONDO_APP_ID === APP_ID && (
                  <div className="m-have-account">
                    <button
                      type="button"
                      onClick={() => thermondoSamlAuthRequest()}
                      className="m-link m-link--light m-login__account-link border-0 bg-transparent"
                    >
                      {i18n.t('loginWithSSO')}
                    </button>

                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

SignInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'signInForm',
  initialValues: {
    remember: false
  }
})(SignInForm);
