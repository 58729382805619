const replace = {
  changeSMID: "Modifier l'ID du Heim OEM",
  reqSMID: 'Heim ID *',
  SMID: 'Heim ID',
  SMIDTolltip: 'L\'ID Heim est un identifiant unique de votre Heim. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'Heim ID',
  smidLabel: 'Heim ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le Heim ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du Heim. La passerelle n\'est pas supprimée.'
};

export default replace;
