const replace = {
  changeSMID: 'Heim ID ändern',
  reqSMID: 'Heim ID *',
  SMID: 'Heim ID',
  SMIDTolltip: 'Die Heim ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'Heim ID',
  smidLabel: 'Heim ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die Heim -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der Heim-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
