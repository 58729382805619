const replace = {
  changeSMID: 'Ændre Heim ID',
  reqSMID: 'Heim ID *',
  SMID: 'Heim ID',
  SMIDTolltip: 'The Heim ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: 'Heim ID',
  smidLabel: 'Heim ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste Heim ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med Heim-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
